export default {
  demografi: {
    nama_pasien: {
      label: 'Nama Pasien',
      widget: 'wtext',
      data: null,
      col: 3
    },
    no_rekam_medis: {
      label: 'No. Rekam Medis',
      widget: 'wtext',
      data: null,
      col: 3
    },
    tanggal_lahir: { widget: 'wdate', data: null, col: 3 },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      data: null,
      col: 3
    },
    folio_id: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'No Folio'
    },
    reg_id: {
      widget: 'wtext',
      data: null,
      label: 'No Reg',
      col: 3
    },
    jenis_kelamin: {
      widget: 'wradio',
      data: [
        { text: 'Laki-Laki', value: 'Laki-Laki' },
        { text: 'Perempuan', value: 'Perempuan' }
      ],
      col: 6
    }
  },
  data_medik: {
    golongan_darah: {
      widget: 'wselect',
      data: [
        { text: 'A', value: 'A' },
        { text: 'B', value: 'B' },
        { text: 'AB', value: 'AB' },
        { text: 'O', value: 'O' }
      ],
      label: 'Golongan Darah',
      col: 3
    },
    tekanan_darah: {
      widget: 'wtext',
      data: null,
      label: 'Tekanan Darah',

      col: 3
    },
    status_tekanan_darah: {
      widget: 'wselect',
      label: 'Status Tekanan Darah',
      data: [
        { text: 'HIPERTENSI', value: 'HIPERTENSI' },
        { text: 'HIPOTENSI', value: 'HIPOTENSI' },
        { text: 'NORMAL', value: 'NORMAL' }
      ],
      col: 6
    },
    penyakit_jantung: {
      widget: 'wradio',
      data: [
        { text: 'Tidak', value: 'Tidak' },
        {
          text: 'Ada',
          value: null,
          field: 'auto'
        }
      ],
      col: 6
    },
    diabetes: {
      widget: 'wradio',
      data: [
        { text: 'Tidak', value: 'Tidak' },
        {
          text: 'Ada',
          value: null,
          field: 'auto'
        }
      ],
      col: 6
    },
    penyakit_lainnya: {
      widget: 'wradio',
      data: [
        { text: 'Tidak', value: 'Tidak' },
        {
          text: 'Ada',
          value: null,
          field: 'auto'
        }
      ],
      col: 6
    },
    hepatitis: {
      widget: 'wradio',
      data: [
        { text: 'Tidak', value: 'Tidak' },
        {
          text: 'Ada',
          value: null,
          field: 'auto'
        }
      ],
      col: 6
    },
    alergi_obat: {
      widget: 'wradio',
      data: [
        { text: 'Tidak', value: 'Tidak' },
        {
          text: 'Ada',
          value: null,
          field: 'auto'
        }
      ],
      col: 6
    },
    alergi_makanan: {
      widget: 'wradio',
      data: [
        { text: 'Tidak', value: 'Tidak' },
        {
          text: 'Ada',
          value: null,
          field: 'auto'
        }
      ],
      col: 6
    },
    oklusi: {
      widget: 'wradio',
      data: [
        { text: 'Normal Bite', value: 'Normal Bite' },
        { text: 'Cross Bite', value: 'Cross Bite' },
        { text: 'Deep Bite', value: 'Deep Bite' }
      ],
      col: 6
    },
    torus_palatinus: {
      widget: 'wradio',
      data: [
        { text: 'Normal Bite', value: 'Normal Bite' },
        { text: 'Cross Bite', value: 'Cross Bite' },
        { text: 'Deep Bite', value: 'Deep Bite' }
      ],
      col: 6
    },
    torus_mandibularis: {
      widget: 'wradio',
      data: [
        { text: 'Tidak Ada', value: 'Tidak Ada' },
        { text: 'Kecil', value: 'Kecil' },
        { text: 'Sedang', value: 'Sedang' },
        { text: 'Besar', value: 'Besar' },
        { text: 'Multiple', value: 'Multiple' }
      ],
      col: 6
    },
    palatum: {
      widget: 'wradio',
      data: [
        { text: 'Dalam', value: 'Dalam' },
        { text: 'Sedang', value: 'Sedang' },
        { text: 'Rendah', value: 'Rendah' }
      ],
      col: 6
    },
    supernumerary_teeth: {
      widget: 'wradio',
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ada', value: null, field: 'auto' }
      ],
      col: 6
    },
    gigi_anomali: {
      widget: 'wradio',
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ada', value: null, field: 'auto' }
      ],
      col: 6
    },
    distema: {
      widget: 'wradio',
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Ada', value: null, field: 'auto' }
      ],
      col: 6
    },
    lain_lain: {
      widget: 'wtextarea',
      data: null,
      label: 'Lain-lain',
      col: 6
    }
  },

  table_gigi: {
    widget: 'wtext',
    property: { attrs: { type: 'number' } },
    data: [
      {
        text: '',
        value: null,
        dataCols: ['18', '28', '38', '48'],
        dataCols_data: ['', '', '', '']
      },
      {
        text: '',
        value: null,
        dataCols: ['17', '27', '37', '47'],
        dataCols_data: ['', '', '', '']
      },
      {
        text: '',
        value: null,
        dataCols: ['16', '26', '36', '46'],
        dataCols_data: ['', '', '', '']
      },
      {
        text: '',
        value: null,
        dataCols: ['15/55', '25/65', '35/75', '45/85'],
        dataCols_data: ['', '', '', '']
      },
      {
        text: '',
        value: null,
        dataCols: ['14/54', '24/64', '34/74', '44/84'],
        dataCols_data: ['', '', '', '']
      },
      {
        text: '',
        value: null,
        dataCols: ['13/53', '23/63', '33/63', '43/83'],
        dataCols_data: ['', '', '', '']
      },
      {
        text: '',
        value: null,
        dataCols: ['12/52', '22/62', '32/62', '42/82'],
        dataCols_data: ['', '', '', '']
      },
      {
        text: '',
        value: null,
        dataCols: ['11/51', '21/61', '31/61', '41/81'],
        dataCols_data: ['', '', '', '']
      }
    ]
  }
}
