<template>
  <div class="m-3">
    <v-row>
      <v-col cols="12" lg="7" md="7" sm="7">
        <v-card color="teal darken-1 mb-2 mt-2" dark>
          <v-card-text>
            <v-row class="mt-3 mb-0">
              <v-col
                v-for="(v, i) in master.demografi"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-1 pt-0 pb-1"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.demografi[i] = e)"
                  :value="data.demografi[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-text class="mt-3 pt-7">
            <h3 class="mb-4">DATA MEDIS</h3>
            <v-row class="mt-0 mb-0">
              <v-col
                v-for="(v, i) in master.data_medik"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.data_medik[i] = e)"
                  :value="data.data_medik[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <h6 class="mb-4">GAMBAR GIGI</h6>

            <v-textarea
              solo
              name="input-7-4"
              label="Solo textarea"
              v-model="odontogram_json"
              style="display: none"
            >
            </v-textarea>

            <v-textarea
              solo
              name="input-7-4"
              label="Solo textarea"
              v-model="odontogram_base64"
              style="display: none"
            >
            </v-textarea>

            <iframe class="framclassOdon" :src="url" frameborder="0"></iframe>
            <h3 class="mb-5">Keterangan :</h3>
            <table class="table table-bordered">
              <tbody>
                <tr v-for="(data, i) in master.table_gigi.data" :key="i">
                  <td
                    class="text-capitalize"
                    style="font-size: 12px; width: 10px"
                    v-bind="
                      typeof data.dataCols === 'undefined'
                        ? { colspan: 4, class: 'text-center font-weight-bold' }
                        : {}
                    "
                  >
                    *
                  </td>
                  <td
                    class="text-capitalize"
                    style="font-size: 12px; text-align: left"
                    v-for="(dcol, j) in data.dataCols"
                    :key="j"
                  >
                    <b>{{ dcol }}</b> : <i>{{ data.dataCols_data[j] }}</i>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="form-group">
              <div
                style="z-index: 999999"
                class="float-group btn-group-vertical mb-10"
                role="group"
                v-if="!!regid"
                aria-label="Vertical button group"
              >
                <v-btn
                  @click="dialogShow = true"
                  class="btn btn-block mt-1"
                  color="primary"
                >
                  Simpan Form
                </v-btn>
                <!-- <button
            @click="cetaknya('download')"
            class="btn btn-success btn-block mt-1"
          >
            Download
          </button>
          <button
            @click="cetaknya('')"
            class="btn btn-danger btn-block mt-1 mb-10"
          >
            Lihat
          </button> -->
                <dialog-confirm
                  :show-dialog="dialogShow"
                  :negative-button="dialogActionNBtn"
                  :positive-button="dialogActionPBtn"
                  :disabled-negative-btn="dialogDisableNBtn"
                  :disabled-positive-btn="dialogDisablePBtn"
                  :progress="dialogProgress"
                  :title="dialogTitle"
                  :message="dialogMessage"
                />
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="5" sm="5" md="5">
        <table class="table table-bordered elevation-5 mt-2 text-left table-sm">
          <thead>
            <tr class="table-primary">
              <th class="text-center" width="33%">GIGI</th>
              <th class="text-center" width="33%">KELUHAN/DIAGNOSA</th>
              <th class="text-center" width="10%">Aksi</th>
            </tr>
          </thead>
          <tbody>
            <tr class="table-warning" v-for="(data, index) in detail_perawatan" :key="data.item_id">
              <td class="p-3">
                <v-text-field
                  dense
                  v-model="data.item_gigi"
                  label="Gigi"
                  outlined
                  class="mt-2"
                ></v-text-field>
                <v-text-field
                  dense
                  outlined
                  v-model="data.item_keluhan_diagnosa"
                  label="Keluhan/Diagnosa"
                  class="mt-2"
                ></v-text-field>
              </td>

              <td class="p-3">
                <v-text-field
                  dense
                  outlined
                  v-model="data.item_perawatan"
                  label="Perawatan"
                  class="mt-2"
                ></v-text-field>
                <v-text-field
                  dense
                  type="date"
                  outlined
                  v-model="data.item_tanggal"
                  label="Tanggal"
                  class="mt-2"
                ></v-text-field>
              </td>
              <td class="text-center pt-10">
                <v-btn
                  @click="deleteItem(index)"
                  class="mt-2"
                  fab
                  color="error"
                  dense
                  outlined
                  x-small
                  elevation="2"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="5" class="text-center">
                <v-btn
                  @click="addItem(null)"
                  class="mt-0"
                  fab
                  color="success"
                  dense
                  outlined
                  x-small
                  elevation="2"
                >
                  <v-icon> mdi-plus </v-icon>
                </v-btn>
              </td>
            </tr>
          </tfoot>
        </table>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar">
      {{ snack_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import SmartWidget from '@/components/SmartWidget.vue'
// eslint-disable-next-line no-unused-vars
import Master from '@/data/rekam-medik-kedokteran-gigi'
// eslint-disable-next-line no-unused-vars
import { mapActions } from 'vuex'
// eslint-disable-next-line no-unused-vars
import Dialog from '@/components/Dialog'
// eslint-disable-next-line no-unused-vars
import {
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrlEmr
} from '@/plugins/supports'
// eslint-disable-next-line no-unused-vars
import store from '@/store/index.js'

export default {
  name: 'KedokteranGigi',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    SmartWidget,
    // eslint-disable-next-line vue/no-unused-components
    DialogConfirm: Dialog
  },
  data () {
    return {
      snackbar: false,
      snack_text: 'Hello, Im a snackbar',
      url_emr: 'http://emr.rs-syafira.com/',
      ext: {
        1: 'Belum Erupsi (UNE)',
        2: 'Erupsi Sebagian (PRE)',
        3: 'Anomali Bentuk (ANO)',
        4: 'Karies (CAR)',
        5: 'Non Vital (NVT)'
      },
      sts: {
        1: 'Sisa Akar',
        2: 'Gigi Hilang',
        3: 'Jembatan',
        4: 'Gigi Tiruan Lepas'
      },
      site: '',
      init_json_odontogram: '',
      hasil_data_odontogram: [],
      url: '',
      odontogram_json: '',
      regid: this.$route.query.registration_id,

      odontogram_base64: '',
      detail_perawatan: [],
      master: Master,
      id_generated: 0,
      user: JSON.parse(localStorage.getItem('user')),
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          jenis_kelamin: '',
          cara_bayar: '',
          folio_id: '',
          reg_id: ''
        },
        data_medik: {
          golongan_darah: '',
          tekanan_darah: '',
          status_tekanan_darah: '',
          penyakit_jantung: '',
          diabetes: '',
          penyakit_lainnya: '',
          hepatitis: '',
          alergi_obat: '',
          alergi_makanan: '',
          oklusi: '',
          torus_palatinus: '',
          torus_mandibularis: '',
          palatum: '',
          supernumerary_teeth: '',
          gigi_anomali: '',
          lain_lain: ''
          // ,
          // odontogram_json: '',
          // odontogram_base64: ''
        }
      },
      rules: {
        required: (value) => {
          // return !isEmpty(value) || 'Tidak Boleh Kosong'
          return true
        }
      },
      dialogShow: false,
      dialogTitle: 'Simpan',
      dialogMessage: 'Simpan Form Sekarang?',
      dialogProgress: false,
      dialogDisableNBtn: false,
      dialogDisablePBtn: false,
      dialogActionNBtn: () => {
        this.dialogShow = false
      },
      dialogActionPBtn: () => this.postSave()
    }
  },
  created () {
    if (this.$route.query.no_rm != null) {
      this.getDataPasien(this.$route.query.no_rm)
      this.getDataGigi(this.$route.query.no_rm)
    }
    setTimeout(() => {
      this.site = 'odontogram-iframe/index.html?rand=' + Math.random()
      this.init_json_odontgoram = this.odontogram_json // Jika baru
      this.url = this.site
    }, 1000)
  },
  methods: {
    ...mapActions(['postFormRekamKedokteranGigi']),
    getDataPasien (v) {
      apiKedua
        .get('emr/detail-pasien?rm=' + v, {})
        .then((response) => {
          this.dataPatient = response.data.results
          console.log('HASIL RESPONSE ' + response)

          // this.dataPatient = JSON.parse(localStorage.getItem('pasien'))
          // this.folioYangDipakai = JSON.parse(
          //   localStorage.getItem('folioIdYangDipakai')
          // )
          this.data.demografi = this.dataPatient
          this.data.demografi.jenis_kelamin = {
            value: this.dataPatient.jenis_kelamin,
            text: this.dataPatient.jenis_kelamin
          }
          this.data.demografi.folio_id = this.$route.query.folio_id
          this.data.demografi.reg_id = this.$route.query.registration_id
          this.data.demografi.sub_unit_id = this.$route.query.sub_unit_id

          this.patient_name = response.data.results.nama_pasien
          // localStorage.setItem('pasien', JSON.stringify(this.dataPatient))

          // this.getResumeRawatJalan(this.folioYangDipakai)
          // this.data.demografi.sub_unit_id = this.pegawai.sub_unit_id
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    cetaknya (view) {
      // console.log(this.folioYangDipakai)
      window
        .open(
          baseUrlEmr +
            `cetak-word-emr/cetak-gigi?no_rm=${this.$route.query.no_rm}&folio_id=${this.$route.query.folio_id}&registration_id=${this.$route.query.registration_id}&view=` +
            view,
          '_blank'
        )
        .focus()
    },
    addItem (item) {
      this.id_generated = this.id_generated + 1
      if (item === null) {
        this.detail_perawatan.push({
          no_rm: this.$route.query.no_rm,
          no_reg: this.$route.query.registration_id,
          item_id: this.id_generated, // item.item_id,
          item_gigi: '', // item.item_gigi,
          item_keluhan_diagnosa: '', // item.item_gigi,
          item_perawatan: '', // item.item_gigi,
          item_tanggal: '',
          item_dokter: true,
          jumlah: 1
        })
      } else {
        this.detail_perawatan.push({
          no_rm: this.$route.query.no_rm,
          no_reg: this.$route.query.registration_id,
          item_id: this.id_generated, // item.item_id,
          item_gigi: item.item_gigi,
          item_keluhan_diagnosa: item.item_keluhan_diagnosa,
          item_perawatan: item.item_perawatan,
          item_tanggal: item.item_tanggal,
          item_dokter: item.item_dokter,
          jumlah: item.jumlah
        })
      }

      localStorage.setItem(
        'isDetailPerwatanChange',
        JSON.stringify(this.detail_perawatan)
      )
    },
    deleteItem (item) {
      this.detail_perawatan.splice(item, 1)
      localStorage.setItem(
        'isDetailPerwatanChange',
        JSON.stringify(this.detail_perawatan)
      )
    },
    postSave () {
      this.dialogProgress = true
      this.dialogDisableNBtn = true
      this.dialogDisablePBtn = true
      this.dialogMessage = 'Mohon tunggu sebentar, sedang menyimpan data...'
      console.log(this.data)
      this.postFormRekamKedokteranGigi({
        data: this.data,
        odontogram_json: this.odontogram_json,
        odontogram_base64: this.odontogram_base64,
        created_by: this.user.employee_id,
        detail_perawatan: this.detail_perawatan
      }).then((res) => {
        this.dialogProgress = false
        this.dialogMessage = res.msg
        setTimeout(() => {
          this.snack_text = res.data.msg
          this.snackbar = true
          this.dialogShow = false
          // this.backButton()
          this.dialogDisableNBtn = false
          this.dialogDisablePBtn = false

          this.dialogMessage = 'Simpan Form Sekarang Ya?'
        }, 1000)
      })

      console.info('postFormRekamKedokteranGigi')
    },
    getDataGigi (v) {
      apiKedua
        .get(
          'emr/simpan-rekam-medik-kedokteran-gigi?norm=' +
            this.$route.query.no_rm +
            '&folio_id=' +
            this.$route.query.folio_id +
            '&reg_id=' +
            this.$route.query.registration_id
        )
        .then((res) => {
          // console.log(res)
          const response = res.data.results
          console.log(response)
          if (response.data) {
            this.data.data_medik = response.data.data_medik
            this.odontogram_json = response.odontogram_json

            // this.data.data_medik.golongan_darah = 'aaa'

            // this.odontogram_json = '________'
            // NGELOAD ULANG GAMBAR ODONTOGRAM YG DAH ADA
            this.init_json_odontogram = this.odontogram_json // Jika baru
          }
          this.site = 'odontogram-iframe/index.html?rand=' + Math.random()
          this.url = this.site
          var ininya = this
          if (response.detail_perawatan !== null) {
            response.detail_perawatan.forEach(function (item, index) {
              ininya.addItem(item)
            })
          }

          //
        })
    },
    updateKeteranganGigi () {
      // JIKA JSON
      if (this.odontogram_json !== '' && this.odontogram_json != null) {
        // console.log('wewewe')
        var odontogramReindex = { tes: '' }
        var odontogramArr = JSON.parse(this.odontogram_json)
        console.log(odontogramArr)
        // console.log(odontogramArr[0].ext)
        var ext_ = this.ext
        var sts_ = this.sts
        odontogramArr.forEach(function (
          item,
          index,
          temp = '',
          ext = ext_,
          sts = sts_
        ) {
          // reindexing
          // odontogramReindex[item.id] = JSON.stringify(item)
          temp = ''
          if (item.sts !== 0) {
            if (temp !== '') {
              temp = temp + ', '
            }
            temp = temp + sts[item.sts]
          }
          if (item.ext !== 0) {
            if (temp !== '') {
              temp = temp + ', '
            }
            temp = temp + ext[item.ext]
          }
          if (temp === '') {
            temp = ' - '
          }
          odontogramReindex[item.id] = temp // JSON.stringify(item)
        })

        // console.log('--' + (this.odontogram_json) + '--')
        this.master.table_gigi.data = [
          {
            text: '',
            value: null,
            dataCols: ['18', '28', '38', '48'],
            dataCols_data: [
              odontogramReindex[18],
              odontogramReindex[28],
              odontogramReindex[38],
              odontogramReindex[48]
            ]
          },
          {
            text: '',
            value: null,
            dataCols: ['17', '27', '37', '47'],
            dataCols_data: [
              odontogramReindex[17],
              odontogramReindex[27],
              odontogramReindex[37],
              odontogramReindex[47]
            ]
          },
          {
            text: '',
            value: null,
            dataCols: ['16', '26', '36', '46'],
            dataCols_data: [
              odontogramReindex[16],
              odontogramReindex[26],
              odontogramReindex[36],
              odontogramReindex[46]
            ]
          },
          {
            text: '',
            value: null,
            dataCols: ['15/55', '25/65', '35/75', '45/85'],
            dataCols_data: [
              odontogramReindex[15] + '/' + odontogramReindex[55],
              odontogramReindex[25] + '/' + odontogramReindex[65],
              odontogramReindex[35] + '/' + odontogramReindex[75],
              odontogramReindex[45] + '/' + odontogramReindex[85]
            ]
          },
          {
            text: '',
            value: null,
            dataCols: ['14/54', '24/64', '34/74', '44/84'],
            dataCols_data: [
              odontogramReindex[14] + '/' + odontogramReindex[54],
              odontogramReindex[24] + '/' + odontogramReindex[64],
              odontogramReindex[34] + '/' + odontogramReindex[74],
              odontogramReindex[44] + '/' + odontogramReindex[84]
            ]
          },
          {
            text: '',
            value: null,
            dataCols: ['13/53', '23/63', '33/63', '43/83'],
            dataCols_data: [
              odontogramReindex[13] + '/' + odontogramReindex[53],
              odontogramReindex[23] + '/' + odontogramReindex[63],
              odontogramReindex[33] + '/' + odontogramReindex[63],
              odontogramReindex[43] + '/' + odontogramReindex[83]
            ]
          },
          {
            text: '',
            value: null,
            dataCols: ['12/52', '22/62', '32/62', '42/82'],
            dataCols_data: [
              odontogramReindex[12] + '/' + odontogramReindex[52],
              odontogramReindex[22] + '/' + odontogramReindex[62],
              odontogramReindex[32] + '/' + odontogramReindex[62],
              odontogramReindex[42] + '/' + odontogramReindex[82]
            ]
          },
          {
            text: '',
            value: null,
            dataCols: ['11/51', '21/61', '31/61', '41/81'],
            dataCols_data: [
              odontogramReindex[11] + '/' + odontogramReindex[51],
              odontogramReindex[21] + '/' + odontogramReindex[61],
              odontogramReindex[31] + '/' + odontogramReindex[61],
              odontogramReindex[41] + '/' + odontogramReindex[81]
            ]
          }
        ]
      }
    },
    pushIframe (datanya) {
      this.hasil_data_odontogram = datanya // .data_json
      // console.log(this.hasil_data_odontogram)
      this.odontogram_json = this.hasil_data_odontogram.data_json // simpan jsonnya
      this.odontogram_base64 = this.hasil_data_odontogram.data_svg // simpan base64 gambar nya
      this.updateKeteranganGigi()
    }
  },
  watch: {
    url: function () {
      this.updateKeteranganGigi()
    }
  },
  mounted () {
    // this.init()
    window.anu = this
  }
}
</script>
<style>
.framclassOdon {
  width: 1100px;
  height: 668px;
  border: 0;
  -ms-transform: scale(0.7);
  -moz-transform: scale(0.7);
  -o-transform: scale(0.7);
  -webkit-transform: scale(0.7);
  transform: scale(0.7);

  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
.float-group {
  position: fixed;
  bottom: 460px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.float {
  position: fixed;
  bottom: 230px;
  right: 80px;
  color: #fff;
  text-align: center;
  z-index: 100;
}
</style>
